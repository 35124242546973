<template>
  <div class="login-container">
    <div class="top-banner">
      规范标准·健康游戏·绿色发展
    </div>
    <div class="login-box-container">
      <div class="login-box">
        <h2 style="color: white">登录</h2>
        <form @submit.prevent="handleSubmit" class="login-form">
          <div class="form-group">
            <input type="text" id="username" v-model="username" required placeholder="请输入用户名"/>
            <div class="error-message" v-if="errors.username">{{ errors.username }}</div>
          </div>
          <div class="form-group">
            <input type="password" id="password" v-model="password" required placeholder="请输入密码"/>
            <div class="error-message" v-if="errors.password">{{ errors.password }}</div>
          </div>
          <button type="submit" :disabled="loading">{{ loading ? '登录中...' : '登录' }}</button>
        </form>
      </div>

    </div>
  </div>
</template>

<script>
import Request from "@/utils/request";

export default {
  name: 'LoginPage',
  data() {
    return {
      username: '',
      password: '',
      loading: false,
      errors: {
        username: '',
        password: '',
      },
    };
  },
  methods: {
    handleSubmit() {
      this.loading = true;
      this.errors = { username: '', password: '' };

      Request({
        url: '/api/login',
        method: 'post',
        data: { username: this.username, password: this.password }
      })
          .then(res => {
            const { data } = res;
            this.$store.dispatch('saveUid', Number(data.userInfo.userId));
            this.$store.dispatch('saveToken', data.token)
                .then(() => {
                  this.$router.push({ name: 'HomePage' });
                })
                .catch(error => {
                  console.error('保存 token 失败:', error);
                });
          })
          .catch(error => {
            if (error.response && error.response.data) {
              const { errors } = error.response.data;
              this.errors = errors || { username: '登录失败', password: '' };
            } else {
              alert(error.message);
            }
            console.error('请求失败:', error);
          })
          .finally(() => {
            this.loading = false;
          });
    },
  },
};
</script>

<style scoped>
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background: url('@/assets/backegroud_login.png') no-repeat center center fixed;
  background-size: cover;
}

.top-banner {
  width: 100%;
  padding: 30px 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.42), rgba(255, 255, 255, 0));
  color: #fff;
  text-align: center;
  font-size: 48px;
  font-weight: bold;
  letter-spacing: 25px;
}


.login-box-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  flex-direction: column;
}

.login-box {
  width: 100%;
  max-width: 350px;
  padding: 40px;
  background-color: rgba(19, 19, 19, 0.27);
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  text-align: center;
  backdrop-filter: blur(10px);
  margin-bottom: 50px;
}

.login-box h2 {
  margin-bottom: 30px;
  color: #fff; /* Changed to white */
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
}

.form-group {
  margin-bottom: 40px;
  text-align: left;
}

.form-group input {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  background-color: rgba(255, 255, 255, 0.15); /* Semi-transparent background */
  border: 1px solid rgba(255, 255, 255, 0.16);
  background: rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  box-sizing: border-box;
  transition: border-color 0.3s, box-shadow 0.3s;
  color: white;
}

.form-group input:focus {
  border-color: #42b983;
  box-shadow: 0 0 8px rgba(66, 185, 131, 0.3);
  outline: none; /* Remove default focus outline */
}

.form-group input::placeholder {
  color: rgba(253, 252, 252, 0.59); /* Gray color for placeholder text */
}

.error-message {
  color: #e74c3c;
  font-size: 12px;
  margin-top: 5px;
}

button {
  width: 100%;
  max-width: 430px;
  padding: 15px;
  font-size: 16px;
  color: #fff;
  background-color: #287631;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #287631;
  transform: scale(1.02);
}

button:disabled {
  background-color: #287631;
  cursor: not-allowed;
}

.footer-text {
  margin-top: 20px;
  font-size: 12px;
  color: #fff;
  text-align: center;
  opacity: 0.8;
}
</style>

<template>
  <header class="header">
    <div class="profile-icon">
<!--      <div  class="avatar" @click="toggleMenu">{{ this.fname }}</div>-->
      <img src="@/assets/avatar.png" alt="Profile Icon" class="avatar" @click="toggleMenu"/>
      <div v-if="showMenu" class="menu">
        <!--        <div class="menu-item" @click="toUser">用户详情</div>-->
        <div class="menu-item" @click="toHome">首页</div>
        <div class="menu-item" @click="toLogin">退出登录</div>
      </div>
    </div>
    <h1 class="custom-letter-spacing" >规范标准·健康游戏·绿色发展</h1>
  </header>
</template>

<script>
export default {
  name: 'AppHeader',
  data() {
    return {
      showMenu: false,
    };
  },
  computed: {
    isLogin() {
      return !!localStorage.getItem("token");
    },
    fname() {
      /*  const user = localStorage.getItem("user");
        const google = user && JSON.parse(user).google;
        const regex = /[A-Za-z]/;
        let match;
        // 使用正则表达式在字符串中查找
        while ((match = regex.exec(google))) {
          // 返回找到的第一个英文字母
          const fname = match[0] && match[0].toUpperCase();
          console.log(fname);
          return fname;
        }*/
      return "U";
    },
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },

    toLogin() {
      // this.$router.push("/login");
      this.$store.dispatch('saveUid','');
      this.$store.dispatch('saveToken', '')
      this.showMenu = false;
      this.$router.push("/login");
      // this.$router.push({path: '/login'});
    },

    toHome() {
      this.$router.push("/");
      // this.$router.push({path: '/login'});
    },



  },
}
</script>

<style scoped>
.header {
  /*background-color: #333;*/
  /*background: linear-gradient(to bottom, rgba(0, 0, 0, 0.42), rgba(255, 255, 255, 0));*/
  color: white;
}

.nav {
  display: flex;
  justify-content: space-around;
}

.nav-list {
  list-style: none;
  display: flex;
}

.nav-item {
  margin-right: 15px;
}

.nav-item a {
  color: white;
  text-decoration: none;
}
.custom-letter-spacing {
  letter-spacing: 25px;
  text-align: center;
  color: #287631;
  font-size: 40px;
  padding-top: 100px;
}






.logo img {
  width: 20px;
  object-fit: contain;
}

.header-text .title {
  /* margin-top: 7px;
  margin-bottom: 15px; */
  font-size: 27px;
  color: #ffffff;
}

.header-text .des {
  font-size: 21px;
  color: #ffffff;
}

.profile-icon {
  position: absolute;
  top: 30px;
  right: 30px;
}

.profile-icon img {
  width: 60px;
  height: 60px;
  object-fit: contain;
}

.profile-icon .avatar {
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  background: #fff;
  font-weight: bold;
  font-size: 32px;
  color: #15BB4F;
  text-align: center;
}

.menu {
  position: absolute;
  width: 100px;
  top: 80px;
  right: 0;
  background: white;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  color: black;
}

.menu-item {
  padding: 10px 0px;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.menu-item:hover {
  background-color: #f0f0f0;
}
</style>

// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import store from './store'
import router from './router'
import ElementPlus from 'element-plus' //全局引入
import 'element-plus/dist/index.css'
// import locale from 'element-ui/lib/locale/lang/zh-CN' // lang i18n

const app = createApp(App);
app.use(router);
app.use(store)
app.use(ElementPlus)
app.mount('#app');



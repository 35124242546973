// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import { isAuthenticated } from '@/js/auth';
import HomePage from '../views/HomePage.vue';
import LoginPage from '../views/LoginPage.vue'; // 更新此行
import GameFAQPage from '../views/GameFAQPage.vue'; // 更新此行
import QualityAssessmentPage from '../views/QualityAssessmentPage.vue'; // 更新此行
import ComprehensiveEvaluation from '../views/ComprehensiveEvaluation.vue'; //

const routes = [
    {
        path: '/home',
        name: 'HomePage',
        component: HomePage,
        meta: { requiresAuth: true }, // 标记需要登录才能访问的页面
    },
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/index',
        redirect: '/home'
    },

    {
        path: '/login',
        name: 'LoginPage', // 更新此行
        component: LoginPage, // 更新此行
    },
    {
        path: '/game/:gameId',
        name: 'GameFAQPage',
        component: GameFAQPage,
        props: true,
    },
    {
        path: '/quality-assessment',
        name: 'QualityAssessmentPage',
        component: QualityAssessmentPage,
        props: route => ({ gameId: route.params.gameId, questionnaire: route.params.questionnaire }),
    },
    {
        path: '/comprehensiveEvaluation/:gameId',
        name: 'ComprehensiveEvaluation',
        component: ComprehensiveEvaluation,
        props: true,
    },

];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // 页面需要登录才能访问
        if (!isAuthenticated()) {
            // 用户未登录，重定向到登录页面
            next({ name: 'LoginPage', query: { redirect: to.fullPath } });
        } else {
            // 用户已登录，允许访问页面
            next();
        }
    } else {
        // 页面不需要登录，直接放行
        next();
    }
});
export default router;

<template>
  <div class="container">
    <AppHeader/>
    <div class="game-faq">

      <h2>游戏问答页面</h2>
      <!--    <p>游戏ID: {{ gameId }}</p>-->
      <div v-for="(section, sectionIndex) in questionnaire" :key="sectionIndex" class="section">
        <h3>{{ sectionIndex + 1 }}、{{ section.title }}</h3>
        <div v-for="(question, questionIndex) in section.questions" :key="questionIndex" class="question">
          <p>{{ sectionIndex + 1 }}.{{ questionIndex + 1 }} {{ question.question }}</p>
          <p> {{ question.description }}</p>
          <div class="options">
            <label v-for="(option, optionIndex) in options" :key="optionIndex">
              <input type="radio" :name="'q' + sectionIndex + questionIndex" :value="option" v-model="question.answer" />
              {{ option }}
            </label>
          </div>
          <textarea v-model="question.comment" placeholder="评语"></textarea>
        </div>
      </div>
      <!--    <button class="submit-button" @click="handleSubmit">提交</button>-->
      <button class="next-button" @click="goToQualityAssessment">下一步：质量指数评测</button>
    </div>
  </div>

</template>

<script>
import axios from 'axios';
import Request from "@/utils/request";
import AppHeader from '@/components/AppHeader.vue';
export default {
  name: 'GameFAQPage',
  components: {
    AppHeader
  },
  data() {
    return {
      gameId: null,
      gameName: null,
      options: ['优秀', '良好', '普通', '平庸', '不足'],
      questionnaire: [],
      testType:1
    };
  },
  mounted() {
    // 从路由参数中获取游戏ID
    this.gameId = this.$route.params.gameId;
    this.gameName = this.$route.params.gameName;
    // 根据游戏ID加载游戏的问答内容
    this.loadGameFAQs();
  },
  methods: {
    loadGameFAQs() {

        Request({
          url: '/api/test_paper/getTestPaper',
          method: 'post',
          data: {testType:this.testType}
        }).then(res => {
          const { data } = res;
          console.log('首页数据:', data);
          this.questionnaire=data.questionnaire
        }).catch(error => {
          console.error('请求失败:', error);
        });

    },
    handleSubmit() {
      const payload = {
        gameId: this.gameId,
        questionnaire: this.questionnaire,
      };

      axios.post('https://your-server-endpoint.com/submit', payload)
          .then(response => {
            console.log('问卷提交成功:', response.data);
            // 提交成功后的处理逻辑
          })
          .catch(error => {
            console.error('问卷提交失败:', error);
            // 提交失败后的处理逻辑
          });
    },
    goToQualityAssessment() {
      this.$router.push({
        name: 'QualityAssessmentPage',
        params: {
          gameId: this.gameId,
          gameName: this.gameName,
          questionnaire: JSON.stringify(this.questionnaire)
        }
      });
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: url('@/assets/backegroud_home.png') no-repeat center center fixed;
  background-size: 100% 100% ;
}
.game-faq {
  padding: 20px;
}

.section {
  margin-bottom: 20px;
}

.question {
  margin-bottom: 10px;
}

.options {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

textarea {
  width: 100%;
  height: 80px;
  resize: none;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.submit-button, .next-button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
}

.submit-button:hover, .next-button:hover {
  background-color: #45a049;
}
</style>

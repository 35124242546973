const IS_ONLINE = true // true 为正式服

module.exports = IS_ONLINE
  ? {
    IS_ONLINE: IS_ONLINE,
    // region: "oss-cn-beijing",
    // accessKeyId: "LTAI4Fnj7NXhGXgbeQikJ5NS",
    // accessKeySecret: "bMWhorh1VIHSB42bVfoI2i6eoVJDv0",
    // bucket: "kachao-online",
    // publicPath: "https://kachao-online.oss-cn-beijing.aliyuncs.com/h5/v113",
    // dist: "/h5/v113",
    baseUrl: "https://apig.hxyz.org.cn", // 配置接口地址
    // mallapi: "https://mallapi.kahe.cn",
    // appId: "wxadbe679888e9101c"
  }
  : {
    IS_ONLINE: IS_ONLINE,
    // region: "oss-cn-beijing",
    // accessKeyId: "LTAI4Fnj7NXhGXgbeQikJ5NS",
    // accessKeySecret: "bMWhorh1VIHSB42bVfoI2i6eoVJDv0",
    // bucket: "kachao-dev",
    // publicPath: "https://kachao-dev.oss-cn-beijing.aliyuncs.com/dist",
    // dist: "/dist",
    baseUrl: "http://127.0.0.1:9599", // 测试接口地址
    // mallapi: "https://cbapitest.jddml.com",
    // appId: "wx166818966fd93c23"
    // baseUrl: "https://api.kahe.cn/rest/v110", // 接口
    // appId: "wxadbe679888e9101c"
  }

<template>
  <div class="container">
    <app-header></app-header>
    <div class="evaluation-form">
      <section class="introduction">
        <img :src="gameInfo.icon" alt="Game Icon">
        <div>{{gameInfo.title}}</div>
        <p>{{gameInfo.depict}}</p>
      </section>
      <h2>{{gameInfo.name}}</h2>
      <el-form :model="form" @submit.prevent="handleSubmit" :rules="rules" >
        <el-form-item label="赛季版本充值金额 (必填)" prop="money" class="item-box">
          <el-input v-model="form.money" placeholder="请输入金额，限整数，如1268" class="form-input"  style="height:45px;"></el-input>
        </el-form-item>
        <el-form-item label="日均游戏时长 (必填)" prop="onlineDuration" class="item-box">
          <el-input v-model="form.onlineDuration" placeholder="请输入小时，可输入小数点后2位，如4.57"  style="height:45px;"></el-input>
        </el-form-item>
        <el-form-item label="运营感受评语 (选填)" prop="comment" class="item-box">
          <el-input type="textarea" v-model="form.comment" placeholder="请输入评语，限2000字符以内"  ></el-input>
        </el-form-item>
        <el-form-item label="" prop="gameFile" class="item-box-upload">
          <el-upload
              class="file-uploader"
              action="#"
              :http-request="uploadHttpRequest"
              :show-file-list="false"
              :before-upload="beforeAvatarUpload"
          >
            <el-button  class="el-icon-upload2">点击上传附件</el-button>
          </el-upload>
          <div>{{ fileName }} </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" native-type="submit" class="btn-submit">提交</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>


<script>
import AppHeader from "@/components/AppHeader";
import Request from "@/utils/request";
import { uploadFile } from '@/utils/upload-file'
export default {
  components: {
    AppHeader
  },
  data() {
    return {
      form: {
        gameId:'',
        money: '',
        onlineDuration: '',
        comment: '',
        file: "",
        name:""
      },
      fileName: "",
      gameInfo:{},
      rules: {
        money: [{required: true, message: '请输入金额', trigger: 'blur'},
          {pattern: /^[0-9]*$/, message: '金额需为数字,限整数', trigger: 'blur'}
        ],
        onlineDuration: [{required: true, message: '请输入游戏时长', trigger: 'blur'},
          {pattern: /^\d*\.?\d{0,2}$/, message: '游戏时长需为数字,可输入小数点后2位', trigger: 'blur'}],
        comment: [{
          validator: (rule, value, callback) => {
            if (value.length > 2000) {
              callback(new Error('评语,限2000字符以内'));
            } else {
              callback();
            }
          },
          trigger: 'blur'
        }
        ]
      }
    };
  },
  mounted() {
    this.form.gameId = parseInt(this.$route.params.gameId, 10);
    this.GetDetail();
  },
  methods: {
    handleSubmit() {
      console.log(this.form);
      this.form.money=  parseFloat(this.form.money)
      this.form.onlineDuration=  parseFloat(this.form.onlineDuration)
      Request({
        url: '/api/user/game/comment/create',
        method: 'post',
        data: this.form
      }).then(res => {
        const {data} = res;
        console.log('数据:', data);
        this.$router.replace({ name: 'HomePage' });
      }).catch(error => {
        console.error('请求失败:', error);
      });
    },
    GetDetail() {
      Request({
        url: '/api/user/game/comment/detail',
        method: 'post',
        data: {gameId: this.form.gameId}
      }).then(res => {
        const {data} = res;
        this.gameInfo=data
        console.log('数据:', data);
      }).catch(error => {
        console.error('请求失败:', error);
      });
    },
    uploadHttpRequest(file) {
      // 模拟文件上传并设置文件名
     this.fileName = file.file.name;
      // 模拟文件上传响应并设置文件URL
     /*  setTimeout(() => {
        this.form.gameIcon = URL.createObjectURL(file.file);
      }, 1000);*/
      console.log(file.file.name)
    },
    beforeAvatarUpload(file) {
      const that = this
      console.log(999)
      that.fileUpload(file)
    },
    fileUpload(file) {
      const that = this
      console.log(123)
      uploadFile(file, 'gamePic').then(response => {
        console.log(response.url)
        that.form.file=response.url

      })
    },
    /* validatePlayTimeInput (event) {
       const value = event.target.value;
       // Regular expression to match numbers with up to 2 decimal places
       const regex = /^\d*\.?\d{0,2}$/;

       if (regex.test(value)) {
         this.form.dailyPlaytime = value;
       } else {
         // Keep the previous valid value if the current value is invalid
         event.target.value =  this.form.dailyPlaytime ;
       }
     },*/
    /*validateAmountInput () {
      this.form.rechargeAmount = this.form.rechargeAmount.replace(/\D/g, '');
    }*/
  },
}
</script>
<style scoped>
.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: url('@/assets/backegroud_home.png') no-repeat center center fixed;
  background-size: 100% 100%;
}

.evaluation-form {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-size: 100% 100%;
  padding: 0px 140px;
  color: #287631;
}

.evaluation-form h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 40px;
}


.el-form-item label {
  margin-bottom: 10px;
  font-size: 18px;
  color: #469B50;
}

.introduction {
  text-align: center;
  padding: 20px;
  background: linear-gradient(to bottom, rgba(216, 255, 169, 0.34), rgba(53, 195, 93, 0.5));
}

.introduction img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.file-uploader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
}
.item-box-upload {
  background: #74CE8A;
  display: flex;
  align-items: center;
  color: #287631;
  font-family: Source Han Sans CN;
  font-weight: 500;
  font-size: 32px;
  background: #E9FFEE;
  text-align: center;
  margin: 0px !important;
  padding-bottom: 20px;
}

.item-box {
  background: #74CE8A;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 72px;
  color: #287631;
  font-family: Source Han Sans CN;
  font-weight: 500;
  font-size: 32px;
  line-height: 45px;
  background: #E9FFEE;
  text-align: center;
  margin: 0px !important;
  margin-top: 20px !important;
}


.item-box /deep/ .el-form-item__content {
  width: 100%;
}

.item-box /deep/ .el-textarea__inner {
  height: 100px;
  font-size: 25px;
}



.item-box /deep/ .el-input__inner {
  text-align: center;
  font-size: 25px;
}

/* 或者，重置 .el-form-item__label 的样式 */
/deep/ .el-form-item__label {
  display: flex;
  /* 重置宽度 */
  width: auto;
  /* 重置字体 */
  font-size: inherit;
  color: inherit;
  /* 重置其他可能的样式 */
  padding: 0;
  margin: 0;
}
/deep/ .el-form-item__content {
    margin-top: 10px;
}


.el-icon-upload2 {
  /*font-size: 27px !important;*/
  height:35px;
  margin-left: 70px;
  margin-right: 10px;

}

.btn-submit {
  width: 100% ;
  height: 50px !important;
  margin-top: 20px;
  font-size: 27px;
}
</style>

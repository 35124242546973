// import qs from 'qs'
import axios from 'axios'
// import { MessageBox, Message } from 'element-ui'
import store from '@/store'
// import {getToken} from '@/utils/auth'
// import Encrypt from '@/utils/encrypt'
import router from '@/router'; // 导入 router
const oss = require('../../oss.config') // 引入 oss.js文件

// create an axios instance
const service = axios.create({
    baseURL: oss.baseUrl, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 5000 // request timeout

})

// 配置请求头
// service.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'

// request interceptor
service.interceptors.request.use(
    config => {
        // do something before request is sent
        const token =  store.getters.getToken
        config.params = config.params || {}
        config.data = config.data || {}
        // 在发送请求之前做某件事
        if (token) {
            // config.headers['Authorization'] = 'Bearer ' + token
            config.headers['x-token'] = token
        }
        const userId = store.getters.getUid; // 从 Vuex store 获取 userId
        // const token2 = store.getters.getToken; // 从 Vuex store 获取 userId

        if (userId) {
            // 添加公共参数 userId
            config.params.userId = userId;
            config.data.userId = userId;
        }

        // console.log('getToken:', token, 'UserId:', userId);
        // console.log('data:',  config.data, 'UserId:', userId);
        // else if (config.method === 'get') {
        //   config.params.current_time = +new Date()
        //   config.params.key = objKeySort(config.params);
        // }

        return config;
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        const res = response.data
        // if the custom code is not 20000, it is judged as an error.
        if (res.code !== 1000) {
        // if (res.code !== 0) {
            console.log(res)
            /* Message({
               message: res.message || 'Error',
               type: 'error',
               duration: 10 * 1000
             })*/
            if (res.code === 9999) {
                // 登录失败，跳转到登录页面
                store.dispatch('saveToken','')
                store.dispatch('saveUid','')
                router.push('/login');
            }
            return Promise.reject(new Error(res.message || 'Error'))
        } else {
            return res
        }
    },
    error => {
        console.log('err' + error) // for debug
        /* Message({
           message: error.message,
           type: 'error',
           duration: 5 * 1000
         })*/
        return Promise.reject(error)
    }
)

export default service

// auth.js

// import {getToken} from "@/utils/auth";

import store from "@/store";

export function isAuthenticated() {
    // 检查用户是否已经登录，这里可以根据你的实际需求来编写逻辑
    // const token = localStorage.getItem('token'); // 假设使用 localStorage 存储 token
    const token = store.getters.getToken; // 假设使用 localStorage 存储 token
    return !!token; // 如果 token 存在，我们认为用户已登录
}

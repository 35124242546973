<template>
  <div class="container">
    <AppHeader class="app-header"/>
    <div class="home">
      <div v-if="mobileGames&&mobileGames.length>0"  class="game-list">
        <h2 class="list-title"><div class="title-icon"></div>手游</h2>
        <div class="game-row">
          <div v-for="(game, index) in mobileGames" :key="index" >
            <GameItem :game="game"/>
          </div>
        </div>
      </div>
      <div v-if="pcGames&&pcGames.length>0" class="game-list">
        <h2  class="list-title"><div class="title-icon"></div>端游</h2>
        <div class="game-row">
          <div v-for="(game, index) in pcGames" :key="index" >
            <GameItem :game="game"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GameItem from '../components/GameItem.vue';
import Request from "@/utils/request";
import AppHeader from '@/components/AppHeader.vue';
export default {
  name: 'HomePage',
  components: {
    GameItem,AppHeader
  },
  data() {
    return {
      mobileGames: [],
      pcGames: [],
    };
  },
  mounted() {
    this.fetchGames();
  },
  methods: {
    fetchGames() {
      Request({
        url: '/api/index',
        method: 'post',
        data: {}
      }).then(res => {
        const { data } = res;
        console.log('首页数据:', data);

        // 处理后端返回的数据
        const games = data.gamelist;

        // 根据游戏类型分类
        this.mobileGames = games.filter(game => game.type === 1);
        this.pcGames = games.filter(game => game.type === 2);
      }).catch(error => {
        console.error('请求失败:', error);
      });
    },
   /* handleClick(game) {
      if (game.submitNum > 1) {
        alert("已经评测过了");
      } else {
        this.$router.push({ name: 'GameFAQPage', params: { gameId: game.id,gameName:game.title } });
      }
    }*/
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: url('@/assets/backegroud_home.png') no-repeat center center fixed;
  background-size: 100% 100% ;
}

.home {
  display: flex;
  flex-direction: column;
padding-left: 100px;
padding-right: 100px;
}

.game-list {
  margin-bottom: 30px;
}

.list-title {
  display:flex;
  align-items: center;
  font-size: 35px;
  margin-bottom: 10px;
  color: #287631;
}

.game-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 每行显示4个 */
  gap: 80px; /* 项目之间的间距 */
}

.title-icon {
  width: 8px;
  height: 28px;
  background: #287631;
  margin-right: 10px;
}


</style>

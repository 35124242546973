<template>
  <div class="container">
    <AppHeader/>
    <div class="quality-assessment">
      <div class="game-faq">
        <h2>游戏问答页面</h2>
        <div v-for="(section, sectionIndex) in quality_questionnaire" :key="sectionIndex" class="section">
          <h3>{{ sectionIndex + 1 }}、{{ section.title }}</h3>
          <div v-for="(question, questionIndex) in section.questions" :key="questionIndex" class="question">
            <p>{{ sectionIndex + 1 }}.{{ questionIndex + 1 }} {{ question.question }}</p>
            <p> {{ question.description }}</p>
            <div class="options">
              <label v-for="(option, optionIndex) in options" :key="optionIndex">
                <input type="radio" :name="'q' + sectionIndex + questionIndex" :value="option" v-model="question.answer" />
                {{ option }}
              </label>
            </div>
            <textarea v-model="question.comment" placeholder="评语"></textarea>
          </div>
        </div>
        <button class="submit-button" @click="handleSubmit">提交</button>
      </div>
    </div>
  </div>

</template>

<script>
import Request from "@/utils/request";
import AppHeader from '@/components/AppHeader.vue';
export default {
  name: 'QualityAssessmentPage',
  components: {
    AppHeader
  },
  props: {
    questionnaire: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      gameId: null,
      gameName: null,
      options: ['优秀', '良好', '普通', '平庸', '不足'],
      quality_questionnaire: [],
      testType: 2,
    };
  },
  mounted() {
    this.gameId = this.$route.params.gameId;
    this.gameName = this.$route.params.gameName;
    this.loadGameFAQs();
  },
  methods: {
    loadGameFAQs() {
      Request({
        url: '/api/test_paper/getTestPaper',
        method: 'post',
        data: { testType: this.testType },
      })
          .then(res => {
            const { data } = res;
            console.log('问卷数据:', data);
            this.quality_questionnaire = data.questionnaire;
          })
          .catch(error => {
            console.error('请求失败:', error);
          });
    },
    handleSubmit() {
      const data = {
        gameId: this.gameId,
        gameName: this.gameName,
        userId: this.$store.getters.getUid,
        status: '1',
        operationalEvaluation: this.questionnaire,
        qualityEvaluation: JSON.stringify(this.quality_questionnaire),
      };

      Request({
        url: '/api/test_paper/create',
        method: 'post',
        data: data,
      })
          .then(res => {
            const { message } = res;
            alert(message);
            this.$router.replace({ name: 'HomePage' });
            console.log('提交成功:', res.data);
          })
          .catch(error => {
            alert(error.message);
            console.error('请求失败:', error);
          });
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: url('@/assets/backegroud_home.png') no-repeat center center fixed;
  background-size: 100% 100% ;
}
.game-faq {
  padding: 20px;
}

.section {
  margin-bottom: 20px;
}

.question {
  margin-bottom: 10px;
}

.options {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

textarea {
  width: 100%;
  height: 80px;
  resize: none;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.submit-button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
}

.submit-button:hover {
  background-color: #45a049;
}
</style>

// import Vue from 'vue'
import { createApp } from 'vue';
import Vuex from 'vuex'

import App from "@/App";




import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

const app = createApp(App);
app.use(Vuex)
const store = createStore({
  state: {
    token: '',
    uid: '' // 这里的 uid 就是 userId
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setUid(state, uid) {
      state.uid = uid;
    }
  },
  actions: {
    saveUserInfo({ commit }, userInfo) {
      commit('setToken', userInfo.token);
      commit('setUid', userInfo.userInfo.id);
      console.log('setToken:', userInfo.token, 'setUid:', userInfo.userInfo.id);
    },
    saveToken({ commit }, token) {
      commit('setToken', token);
    },
    saveUid({ commit }, uid) {
      commit('setUid', uid);
    }
  },
  getters: {
    getToken: state => state.token,
    getUid: state => state.uid
  },
  plugins: [createPersistedState()] // 使用 vuex-persistedstate 插件
});

export default store;


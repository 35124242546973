import axios from 'axios'
// import request from '@/utils/request'
const oss = require('../../oss.config') // 引入 oss.js文件
// import { getToken } from '@/utils/auth'
import store from "@/store";

const photo_format = ['jpg', 'jpeg', 'png', 'webp', 'bmp']
const video_format = ['mp4', 'rm', 'rmvb', 'mpg', 'mpeg', '3gp', 'm4v', 'avi', 'flv', 'ogg']

/**
 * file : 单文件
 * type : 接口需要的参数，1-房产相关，2-用户相关(后台头像)
 */
export function uploadFile(file, name) {
  const extension = file.name.slice(file.name.lastIndexOf('.') + 1).toLowerCase() // 文件后缀名
  let file_type = 'document' // 文档 or 图片 or 视频
  console.log(extension)
  if (photo_format.includes(extension)) {
    file_type = 'image'
  } else if (video_format.includes(extension)) {
    // eslint-disable-next-line no-unused-vars
    file_type = 'video'
  }
  const token = store.getters.getToken
  return new Promise((resolve, reject) => {
    const params = new FormData() // 创建form对象
    params.append('savePath', name) // 通过append向form对象添加数据
    params.append('file', file) // 通过append向form对象添加数据
    const instance = axios.create({
      baseURL: oss.baseUrl + '/admin/upload',
      timeout: 1000 * 20,
      headers: {
        'Content-Type': 'multipart/form-data',
        'x-token': token
      }
    })
    instance.post(oss.baseUrl + '/api/upload', params).then(
      rs => {
        console.log(rs)
        resolve({ url: rs.data.data.imageUrl })
      }
    ).catch(err => {
      reject(err)
    })
  })
}

export default {
  uploadFile,
  photo_format,
  video_format
}
